<template>
	<div class="container">
		<div class="d-flex justify-content-between align-items-center mb-4">
			<div>
				<h3 class="h3 mb-3">編輯排序</h3>
				<nav style="--bs-breadcrumb-divider: '>'">
					<ol class="breadcrumb mb-0">
						<li class="breadcrumb-item">
							<router-link :to="{ name: 'Template' }">表單範本管理</router-link>
						</li>
						<li class="breadcrumb-item active">編輯排序</li>
					</ol>
				</nav>
			</div>
		</div>

		<table class="table mb-5">
			<thead class="bg-light">
				<tr>
					<th></th>
					<th>表單範本名稱</th>
					<th>建立日期</th>
					<th class="text-center">使用次數</th>
				</tr>
			</thead>
			<draggable
				:list="dataList"
				item-key="id"
				tag="tbody"
				ghost-class="ghost"
				@start="dragging = true"
				@end="dragging = false"
				handle=".handle"
			>
				<template #item="{ element }">
					<tr>
						<td>
							<a class="handle" role="button"><font-awesome-icon :icon="['fas', 'bars']" /></a>
						</td>
						<td>{{ element.title }}</td>
						<td>{{ element.created_at }}</td>
						<td class="text-center">{{ element.use_count }}</td>
					</tr>
				</template>
			</draggable>
		</table>

		<div class="d-flex justify-content-center">
			<router-link :to="{ name: 'Template' }" class="btn btn-secondary me-3">返回</router-link>
			<button class="btn btn-primary" @click="submit()">存檔</button>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { apiTemplate, apiTemplateChangeOrder } from "@/assets/js/api.js";

import { textEllipsis } from "@/assets/js/common.js";
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import draggable from "vuedraggable";

export default {
	name: "TemplateSort",
	components: {
		draggable,
	},
	setup() {
		const store = useStore();

		const dragging = ref(false);
		const dataList = ref([]);

		const setPageLoading = (isLoading) => store.dispatch("setPageLoading", isLoading);

		const getList = () => {
			setPageLoading(true);

			apiTemplate({
				method: "get",
			})
				.then((response) => {
					dataList.value = response.data.data;

					setPageLoading(false);
				})
				.catch(() => {
					setPageLoading(false);
				});
		};

		const submit = () => {
			const data = dataList.value.map((item, index) => {
				return {
					id: item.id,
					sort: index,
				};
			});

			apiTemplateChangeOrder(data).then((response) => {
				if (response.data.status == "0") {
					Swal.fire({
						icon: "success",
						text: response.data.message,
					});
					getList();
				} else {
					Swal.fire({
						icon: "warning",
						text: response.data.message,
					});
				}
			});
		};

		onMounted(() => {
			getList();
		});

		return {
			dataList,
			dragging,
			getList,
			submit,
			textEllipsis,
		};
	},
};
</script>
<style lang="scss" scoped>
.ghost {
	opacity: 0.3;
}
</style>
